<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,right:'说明',defriend:true}"></v-header>
        </div>
        <div class="tip">
            <p>最多可以拉黑(<span>0</span>/50),会员用户无限制</p>
        </div>
        <div class="search-box">
            <div class="left">
                <input type="text" placeholder="输入昵称进行搜索">
                <van-icon class="icon" color="#333" name="search" size="20" />
            </div>
            <div class="right">
                <button>搜索</button>
            </div>
        </div>
        <div class="none">
            <img src="../../assets/images/none.png" alt="">
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .tip{
            width: 100%;
            height: 20px;
            background-color: #438dcd;
            p{
                padding: 0;
                margin: 0;
                font-size: 12px;
                color: #fff;
                text-align: center;
                line-height: 20px;
            }
        }
        .search-box{
            width: 100%;
            height: 48px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            .left{
                position: relative;
                input{
                    border: none;
                    background-color: #eee;
                    border-radius: 6px;
                    font-size: 14px;
                    width: 260px;
                    height: 32px;
                    text-indent: 32px;
                }
                .icon{
                    position: absolute;
                    top: 8px;
                    left: 10px;
                    color: #438dcd;
                }
            }
            .right{
                button{
                    width: 60px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 16px;
                    border: none;
                    background-color: #438dcd;
                    border-radius: 4px;
                    color: #fff;
                }
            }
        }
        .none{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            img{
                width: 36px;
                height: 36px;
            }
        }
    }
</style>